<template>

  <v-row class="inner-content">
    <v-col>
      <KeywordCollectorBlacklistTable />
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'KeywordCollectorManageBlacklist',

  components: {
    KeywordCollectorBlacklistTable: () => import('@/components/tables/KeywordCollectorBlacklistTable.vue')
  },
}
</script>
